<template>
	<ValidationForm #default="{ handleSubmit, reset }">
		<AdministrationUsersLayout
			user-type="Admin Portal"
		>
			<template #content>
				<AdminPortalUserForm
					:user.sync="user"
					:reset-validation="reset"
					:user-can-create-update="userCanCreateUpdate"
				>
					<template #title>
						{{ userCanCreateUpdate ? isEdit ? 'Edit' : 'Create New' : 'View' }} Admin Portal User
					</template>
				</AdminPortalUserForm>
			</template>
			<template #footer>
				<div class="d-flex">
					<SecondaryActionButton
						class="mr-4"
						@click="navigateToOverview"
					>
						Back to Overview
					</SecondaryActionButton>
					<v-spacer />
					<PrimaryActionButton
						v-if="userCanCreateUpdate"
						@click="handleSubmit(upsertUser)"
					>
						{{ !isEdit ? 'Create' : 'Save' }}
					</PrimaryActionButton>
				</div>
			</template>
		</AdministrationUsersLayout>
	</ValidationForm>
</template>
<script>
import { mapGetters } from 'vuex'
import AdministrationUsersLayout from '../administration-users-layout.vue'
import ValidationForm from '../../../../../../shared/components/validation-form.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import AdminPortalUserForm from './admin-portal-user-form.vue'
import { ADMIN_PORTAL_USERS } from '../../../../router/route-names.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { adminPortalUserType, adminPortalProductRoles } from '../../../../../../shared/state/admin-portal-users-and-lookup-data.js'
import { userHasAllBrands } from '../../../../../../shared/state/brands.js'
import { upsertAdminPortalUser } from '../../../../../../shared/utils/api/admin-portal-users.js'
import { CAN_SEE_CONSENT_BANNERS, USERS_MODULE_FULL_PERMISSIONS, ADMIN_PORTAL_USERS_MODULE_FULL_PERMISSIONS, SEE_COOKIE_MODULE_AREA } from '../../../../../../shared/permissions/admin-portal-permissions.js'

export default {
	components: {
		AdministrationUsersLayout,
		SecondaryActionButton,
		PrimaryActionButton,
		ValidationForm,
		AdminPortalUserForm
	},
	props: {
		userToEdit: Object
	},
	setup () {
		return {
			showSnackbar,
			adminPortalUserType
		}
	},
	data () {
		return {
			user: JSON.parse(JSON.stringify(this.userToEdit || {
				forename: '',
				surname: '',
				emailAddress: '',
				telephoneNo: null,
				mobileNo: null,
				defaultDashboard: null,
				brandIds: [],
				isActive: true,
				userRoleId: null,
				legacyPermissionsData: []
			}))
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		isEdit () {
			return Boolean(this.userToEdit)
		},
		userCanCreateUpdate () {
			if (!this.isEdit) {
				if (this.productAreaPermission(USERS_MODULE_FULL_PERMISSIONS) === true) {
					return true
				}
				return this.productAreaPermission(ADMIN_PORTAL_USERS_MODULE_FULL_PERMISSIONS)
			} else {
				if (this.productAreaPermission(USERS_MODULE_FULL_PERMISSIONS) === true && userHasAllBrands(this.user.brandIds)) {
					return true
				}
				return this.productAreaPermission(ADMIN_PORTAL_USERS_MODULE_FULL_PERMISSIONS) && userHasAllBrands(this.user.brandIds)
			}
		},
		selectedPermissionIds () {
			const userRole = adminPortalProductRoles.value.find(role => role.productRoleId === this.user?.userRoleId)
			return userRole.productRolePermissions.map(permission => {
				return permission.permissionId
			})
		}
	},
	methods: {
		navigateToOverview () {
			this.$router.push({ name: ADMIN_PORTAL_USERS })
		},
		async upsertUser () {
			const user = {
				userId: this.user.userId ?? null,
				forename: this.user.forename,
				surname: this.user.surname,
				emailAddress: this.user.emailAddress,
				defaultDashboard: this.user.defaultDashboard,
				isActive: this.user.isActive,
				telephoneNumber: this.user.telephoneNo,
				mobileNumber: this.user.mobileNo,
				brandIds: this.user.brandIds,
				userTypeId: adminPortalUserType.value,
				legacyPermissionsData: this.user.legacyPermissionsData ?? [],
				userRoleId: this.user.userRoleId
			}
			const userHasCookiePermission = this.selectedPermissionIds.includes(CAN_SEE_CONSENT_BANNERS) || this.selectedPermissionIds.includes(SEE_COOKIE_MODULE_AREA)
			const cookieModuleDashboard = 0
			if (this.user.defaultDashboard === cookieModuleDashboard && !userHasCookiePermission) {
				showSnackbar({ color: 'red', text: 'You cannot set the Cookie Module Default Dashboard without "Read" permission for Cookie Banners' })
				return
			}
			await upsertAdminPortalUser(user)
			showSnackbar(!this.isEdit ? 'This user has been created' : 'This user has been saved')
			this.navigateToOverview()
		}
	}
}
</script>
